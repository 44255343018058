// import React, { useState }from 'react';
// import PropTypes from 'prop-types';
// // import SwipeableViews from 'react-swipeable-views';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid'
// import Box from '@material-ui/core/Box';
// import { Button, TextField, Paper, CardContent, Card, Container,CssBaseline,} from "@material-ui/core"
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Alert } from '@material-ui/lab';
// import { Link } from 'react-router-dom'

// // Firebase Integration
// import firebase from "../firebase"

// //copyright
// function Copyright() {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyright © "}
// 			<Link
// 				style={{ color: "#0133C0" }}
// 				href="https://caritasrevolution.com"
// 			>
// 				Caritas Revolution
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	)
// }

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles((theme) => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 	},
//   title: {
//     fontSize: 24,
//     marginLeft: theme.spacing(5),
//     padding: theme.spacing(3),
//   },
//   right: {
//     flex: 1,
//     display: 'flex',
//     justifyContent: 'flex-end',
//   },
//   image: {
//     marginLeft: theme.spacing(10),
//     padding: theme.spacing(2),
//   },
//   field: {
//     '& label.Mui-focused': {
//       color: '#0133C0',
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: '#0133C0',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#0133C0',
//       },
//       '&:hover fieldset': {
//         borderColor: '#0133C0',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#0133C0',
//       },
//     },
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//     backgroundColor: "#0133C0",
//     color: '#FFF',
//     "&:hover":{
//       backgroundColor: "#214DC5"
//     },
//   },
//   save: {
//     margin: theme.spacing(3, 15, 2),
//     backgroundColor: "#0133C0",
//     color: '#FFF',
//     "&:hover":{
//       backgroundColor: "#214DC5"
//     },
//   },
//  button: {
//   fontSize: 18,
//   textTransform: 'capitalize',
//   color: "#0133C0",
//   "&:hover":{
//       color: "#214DC5",
//       backgroundColor: '#FFF'
//   },
//   marginRight: theme.spacing(3),
//   fontWeight: 'bold'
// },
// typography2: {
//   fontWeight: 'bold',
//   fontSize: '18px',
//   marginLeft: theme.spacing(3),
//   padding: theme.spacing(1)
// },
// container: {
//   marginLeft: theme.spacing(4),
// },
// card: {
// 	marginTop: "60px",
// 	paddingLeft: "20px",
// 	paddingRight: "20px",
// 	paddingBottom: "20px",
// },
// form: {
// 	width: "100%", // Fix IE 11 issue.
// 	marginTop: theme.spacing(5),
// },
// }));

// export default function Profile() {
//   const classes = useStyles();
//   // const user = auth.currentUser;
//   const theme = useTheme();
//   const [value, setValue] = React.useState(0);

//   //Reset Password
//   const [phone, setPhone] = useState("");
//   const [address, setAddress] = useState("");
//   const [email, setEmail] = useState("");
//   const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
//   const [error, setError] = useState(null);

  
//   const onChangeHandler = event => {
//     const { name, value } = event.currentTarget;
//     if (name === "Email") {
//       setEmail(value);
//     }
//   };

//   function sendResetEmail(event) {
//     event.preventDefault();
//     // console.log('email: '+email)
//     firebase
// 				.auth()
//     .sendPasswordResetEmail(email, {url: "https://admin.caritasrevolution.com"})
//     .then(() => {
//       setEmailHasBeenSent(true);
//       setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
//     })
//     .catch(() => {
//       setError("Error resetting password");
//     });
//   }

//   function saveData(e) {
//     e.preventDefault();
//     firebase
//     .auth()
//     .then(() => {
      
//     })
//     .catch(() => {
//       setError("Failed");
//     });
//   }

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleChangeIndex = (index) => {
//     setValue(index);
//   };

//   const disabled = email === "";

//   return (
//     // <React.Fragment>
// 	<Container component="main" maxWidth="xs">
// 		<Card className={classes.card}>
// 				<CardContent>
// 				<CssBaseline />
// 				<div className={classes.paper}>
// 				<Typography
// 							component="h1"
// 							variant="h5"
// 							style={{ fontWeight: "bold" }}
// 						>
// 						Forgot Password
// 						</Typography>
// 						<form action="" className={classes.form}>
//                  {emailHasBeenSent && (
//                    <Alert severity="success">
//                      An email has been sent to you!
//                    </Alert>
//                  )}
//                  {error !== null && (
//                  <Alert severity="error">{error}</Alert>
//                 )}
// 				<br />
//                  <Grid container spacing={2}>
                  
//                    <Grid item xs={12}>
//                          <TextField
//                             className={classes.field}
//                             variant="outlined"
//                             required
//                             fullWidth
//                             id="email"
//                             label="Email"
//                             name="email"
//                             autoComplete="email"
//                             onChange={e => setEmail(e.target.value)}  
//                             value={email}
//                           />
//                     </Grid>
//                     <Grid item xs={12}>
//                       <Button 
// 					  	disabled={disabled} 
// 						  className={classes.submit} 
// 						  type="submit" 
// 						  variant="contained" 
// 						  fullWidth
// 						  onClick={sendResetEmail} 
//                           color="primary" > 
// 						  Send me a reset link
// 					</Button>
//                     </Grid> 
					
//           </Grid>
//         </form>
//         <div style={{padding: '10px'}} />
// 					<Typography align="center">
//                			<Link underline="always" to="/login" className={classes.link} style={{ textDecoration: "none",color: '#2057c0', fontSize: '18px'}}>
//                            Back to Login
//                        </Link>
//                     </Typography>
// 				</div>
// 				</CardContent>
// 		</Card>
// 	</Container>
  
    
//   );
// }

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@material-ui/core";

import firebase from "../firebase";

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [toastOpen, setToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    console.log("sending email to: ", email);
    try {
      await firebase.auth().sendPasswordResetEmail(email, {url: "https://admin.caritasrevolution.com"});
      setOpen(false);
      setToastOpen(true);
      setToastMessage("Password reset email sent!");
    } catch (error) {
      setToastOpen(true);
      setToastMessage(error.message);
    }
  };

  const handleOnChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleToastClose = () => {
    setToastOpen(false);
    setToastOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Forgot Password?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Password Reset?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A password reset will be sent to the following email:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            style={{width: '100%'}}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        message={toastMessage}
      ></Snackbar>
    </div>
  );
}