import axios from "axios";
import firebase from "../firebase";
import Cause from "../models/cause";
import { salesforceBackURL } from "../secrets";

const firestore = firebase.firestore();

export const getCauses = async () => {
  try {
    const data = await firestore.collection("cause_types").get();
    let array = [];
    data.forEach((doc) => {
      const cause = new Cause({ id: doc.id, ...doc.data() });
      array.push(cause);
    });
    return array;
  } catch (error) {
    throw error;
  }
};

export const addCause = async (cause) => {
  try {
    await firestore.collection("cause_types").doc().set(cause);
    let query_newCause = await firestore
      .collection("cause_types")
      .where("cause_display_name", "==", cause.cause_display_name)
      .get();

    if (query_newCause.docs.length > 0) {
      if (
        query_newCause.docs[0].data() !== null &&
        query_newCause.docs[0].data() !== undefined
      ) {
        let newCause = query_newCause.docs[0].data();

        newCause["id"] = query_newCause.docs[0].id;
        await axios.post(
          salesforceBackURL + "addNewCause",
          JSON.parse(JSON.stringify(newCause))
        );
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getCause = async (id) => {
  try {
    const cause = await firestore.collection("cause_types").doc(id);
    const data = await cause.get();
    return data.data();
  } catch (error) {
    throw error;
  }
};

export const updateCause = async (id, data) => {
  try {
    await firestore.collection("cause_types").doc(id).update(data);
    await axios.post(salesforceBackURL + "updateCause", {
      id: id,
      data: data,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteCause = async (id) => {
  try {
    await firestore.collection("cause_types").doc(id).delete();
    await axios.post(salesforceBackURL + "deleteCause", { id: id });
  } catch (error) {
    throw error;
  }
};
