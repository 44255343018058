import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  makeStyles,
  Container,
  Typography,
  Button,
  Grid,
  IconButton,
  Hidden,
  Box,
  Collapse,
  Divider,
  TextField,
  setRef,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircle,
  Edit,
  Delete,
  Visibility,
  VisibilityOff,
  CheckCircleOutline,
  CheckCircle,
} from "@material-ui/icons";
import { ClimbingBoxLoader, ScaleLoader } from "react-spinners";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { ToastContainer, toast } from "react-toastify";
import {
  getProjects,
  addProject,
  getProject,
  updateProject,
  deleteProject,
  updateProjectHide,
  updateProjectStatus,
} from "../data/projectData";
import ProjectDialog from "./ProjectDialog";
import firebase from "../firebase";
import { getCauses, updateCause } from "../data/causeData";
import { Modal } from "react-bootstrap";
import { TableHeadRow } from "mui-datatables";
import Swal from "sweetalert2";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import swal from "sweetalert";
// import emailjs from '@emailjs/browser';

const Project = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [causes, setCauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [causeDocId, setCauseDocId] = useState({});
  const [formMode, setFormMode] = useState(true);
  const [projectId, setProjectId] = useState("");
  const [causeDisplayName, setCauseName] = useState("");
  const [cause_id, setCauseId] = useState();
  const [donated_amount, setDonatedAmount] = useState(0);
  const [project_name, setProjectName] = useState("");
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");
  const [youtube_video_id, setVideo] = useState("");
  const [project_description, setProjectDescription] = useState("");
  const [project_ngo_location, setProjectNGO] = useState("");
  const [project_content, setProjectContent] = useState("");
  const [contact, setChangeContact] = useState("");
  const [amount, setAmountGoal] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectTotalPercent, setProjectTotalPercent] = useState({});
  const [modal, setModal] = useState(false);
  const [projectDesc, setProjectDesc] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [projectBtnName, setProjectBtnName] = useState("Completed Project");
  const [isShown, setIsShown] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputimageFields, setinputimageFields] = useState([]);
  const [addmorefield, setAddmorefield] = useState([]);
  const [project_milestones, setProjectmilestone] = useState([]);
  const [showInputForm, setShowInputForm] = useState(false);
  const [donationPopup, setDonationPopup] = useState(false);
  const [donationAmount, setDonationAmount] = useState();
  const [refundAmount, setRefundAmount] = useState(0);
  const [stripid, setStripId] = useState("");
  const [userdetail, setuserDetails] = useState(null);
  const [projectStatus, setProjectStatus] = useState(false);

  const getShortDescription = (text, maxLength) => {
    const words = text.split(" ");
    if (words.length <= maxLength) {
      return { text, wordLength: false };
    }
    return {
      text: words.slice(0, maxLength).join(" ") + "...",
      wordLength: true,
    };
  };

  const override = `
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: red;
    `;

  const addMoreInputFields = () => {
    let field = { project_milestones: "" };
    setAddmorefield([...addmorefield, field]);
  };

  const addMoreimageInputFields = () => {
    let field = { image: "" };
    setinputimageFields([...image, field]);
  };

  const handleMoreMilestone = (index, event) => {
    let data = [...project_milestones];
    data[index] = event.target.value;
    setProjectmilestone(data);
  };

  const handleDeleteImage = (index) => {
    firebase
      .storage()
      .refFromURL(image[index])
      .delete()
      .then(() => {
        const projectsRef = firebase.firestore().collection("projects");
        console.log("projectsRef" + JSON.stringify(projectsRef));
        projectsRef
          .where("images", "array-contains", image[index])
          .get()
          .then((snapshot) => {
            console.log("snapshot" + snapshot);
            snapshot.forEach((projectDoc) => {
              // Get the images array from the project document
              const images = projectDoc.data().images;

              // Remove the image URL from the images array
              const updatedImages = images.filter(
                (url) => url !== image[index]
              );

              // Update the images array in the project document
              projectsRef
                .doc(projectDoc.id)
                .update({ images: updatedImages })
                .then(() => {
                  console.log(
                    "Image reference removed successfully from Firestore"
                  );
                })
                .catch((error) => {
                  console.error(
                    "Error removing image reference from Firestore: ",
                    error
                  );
                });
            });
          })
          .catch((error) => {
            console.error(
              "Error removing image reference from Firestore: ",
              error
            );
          });

        const newArray = [...image];
        newArray.splice(index, 1);
        setImage(newArray);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setProjectTotalPercent({});
    setProjectDetail([]);
  };

  const handleCauseName = (cause) => {
    setCauseDocId(cause.id);
    setCauseName(cause.cause_display_name);
    setCauseId(parseInt(cause.cause_id));
    setProjectCount(parseInt(cause.project_count));
  };

  const handleDonatedAmount = (event) => {
    const donatedAmount = parseInt(event.target.value);
    setDonatedAmount(donatedAmount);
  };

  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  // const handleImage = async (event) => {
  //   if (!event.target.files.length) return;
  //   const file = event.target.files[0];
  //   const storageRef = firebase.storage().ref();
  //   const iconsRef = storageRef.child(`images/projects/${file.name}`);
  //   const snapshot = await iconsRef.put(file);
  //   const downloadUrl = await snapshot.ref.getDownloadURL();
  //   setImage(downloadUrl.toString());
  // };

  const handleImage = async (index, event) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    const storageRef = firebase.storage().ref();
    const iconsRef = storageRef.child(`images/projects/${file.name}`);
    const snapshot = await iconsRef.put(file);
    const downloadUrl = await snapshot.ref.getDownloadURL();
    console.log(downloadUrl);
    const newArray = [...image];
    newArray[index] = downloadUrl;
    console.log("image", newArray);
    setImage(newArray);
    // setImage({ index, downloadUrl.toString() })
  };

  const addTableRows = () => {
    let newfield = { youtube_video_id: "" };
    setInputFields([...inputFields, newfield]);
  };
  const addInputField = () => {
    setInputFields([{ project_name: "" }]);
    setShowInputForm(true);
  };
  const handleVideo = (index, event) => {
    let data = [...youtube_video_id];
    data[index] = event.target.value;
    setVideo(data);
  };

  const deleteVideo = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleProjectNGO = (event) => {
    setProjectNGO(event.target.value);
  };
  const handleContact = (event) => {
    setChangeContact(event.target.value);
  };
  const handleProjectContent = (event) => {
    setProjectContent(event.target.value);
  };

  const handleProjectDescription = (event) => {
    setProjectDescription(event.target.value);
  };

  const handleAmountGoal = (event) => {
    const projectCount = parseInt(event.target.value);
    setAmountGoal(projectCount);
  };

  const getlist = async (status) => {
    try {
      setLoading(true);
      const list = await getProjects(status);
      setProjects(list);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  const getOneProject = async (id, status) => {
    try {
      setProjectStatus(status);
      setFormMode(false);
      setProjectId(id);
      const response = await getProject(id);
      setCauseName(response.cause_display_name);
      setCauseId(response.cause_id);
      setDonatedAmount(response.donated_amount);
      setProjectName(response.project_name);
      setCountry(response.project_location.country);
      setImage(response.project_assets.image);
      setProjectmilestone(response.project_milestones);
      setVideo(response.project_assets.youtube_video_id);
      setProjectDescription(response.project_description);
      setProjectNGO(response.project_ngo_location);
      setChangeContact(response.contact);
      setProjectContent(response.project_content);
      setAmountGoal(response.project_funding_goal.amount);
      if (
        response.project_breakdown !== undefined &&
        response.project_breakdown !== null
      ) {
        setProjectDetail(response.project_breakdown);
      }
      if (
        response.project_totalpercent !== undefined &&
        response.project_totalpercent !== null
      ) {
        setProjectTotalPercent(response.project_totalpercent);
      }
      const causes = await getCauses();
      setCauses(causes);
      setOpen(true);
    } catch (error) {
      toast.error("getOneProject", error.message);
    }
  };

  const deleteHandler = async (id) => {
    Swal.fire({
      title: `Are you sure you want to Delete this project?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, Delete it!`,
    }).then(async (result) => {
      console.log(result, "result", result.isConfirmed);
      if (result.isConfirmed) {
        try {
          let xCauseDocId = causeDocId;
          console.log(xCauseDocId, "xcausseid");
          if (!xCauseDocId) {
            await getOneProject(id);
            xCauseDocId = await firebase
              .firestore()
              .collection("cause_types")
              .where({ cause_id })
              .get()
              .data().id;
          }
          // Fetch the causeDocId using a query
          // const querySnapshot = await firebase
          //     .firestore()
          //     .collection("cause_types")
          //     .where("cause_id", "==", id)
          //     .get();

          // Get the first document that matches the query
          // if (!querySnapshot.empty) {
          //   xCauseDocId = querySnapshot.docs[0].id;
          // }
          // }
          await deleteProject(id);
          // await updateCause(xCauseDocId, {
          //   project_count: parseInt(projectCount - 1),
          // });

          // Clear the projects and reload the list
          setProjects([]);
          setLoading(true);
          getProjects().then((list) => {
            setProjects(list);
            setLoading(false);
          });
          // getlist();
          Swal.fire(`The project has been Deleted Successfully.`, "success");
          // toast.success("Project Deleted Successfully");
        } catch (error) {
          Swal.fire(
            "Error",
            `An error occurred while deleting this project.`,
            "error"
          );
          // toast.error("deleteHandler", error.message);
        }
      }
    });
  };

  // const deleteHandler = async (id) => {
  //       try {
  //         let xCauseDocId = causeDocId;
  //         if (!xCauseDocId) {
  //           await getOneProject(id);
  //             xCauseDocId = await firebase
  //               .firestore()
  //               .collection("cause_types")
  //               .where({ cause_id })
  //               .get()
  //               .data().id;
  //           }
  //           // Fetch the causeDocId using a query
  //           // const querySnapshot = await firebase
  //           //     .firestore()
  //           //     .collection("cause_types")
  //           //     .where("cause_id", "==", id)
  //           //     .get();
  //
  //           // Get the first document that matches the query
  //           // if (!querySnapshot.empty) {
  //           //   xCauseDocId = querySnapshot.docs[0].id;
  //           // }
  //         // }
  //         await deleteProject(id);
  //         await updateCause(xCauseDocId, {
  //           project_count: parseInt(projectCount - 1),
  //         });
  //
  //         // Clear the projects and reload the list
  //         setProjects([]);
  //         setLoading(true);
  //         getProjects().then((list) => {
  //           setProjects(list);
  //           setLoading(false);
  //         });
  //         // getlist();
  //         Swal.fire('Success', 'Project Deleted Successfully', 'success');
  //
  //         // toast.success("Project Deleted Successfully");
  //       } catch (error) {
  //         Swal.fire('Error', 'An error occurred while deleting this project', 'error');
  //         // toast.error("deleteHandler", error.message);
  //       }
  //     };

  const handleAdd = async () => {
    const causes = await getCauses();
    setCauses(causes);
    setOpen(true);
    setFormMode(true);
    setCauseName("");
    setCauseId("");
    setDonatedAmount(0);
    setProjectName("");
    setCountry("");
    setImage([]);
    setVideo([]);
    setProjectmilestone([]);
    setProjectDescription("");
    setProjectNGO("");
    setProjectContent("");
    setChangeContact("");
    setAmountGoal(0);
    setProjectDetail([]);
    setProjectTotalPercent({});
  };

  const addProjectHandler = async (data, totalpercent) => {
    try {
      const project = {
        cause_display_name: causeDisplayName,
        cause_id,
        donated_amount,
        project_name,
        project_location: {
          country,
        },
        project_assets: {
          image,
          youtube_video_id,
        },
        project_description,
        project_funding_goal: {
          amount,
        },
        project_milestones,
        project_ngo_location,
        project_content,
        contact,
        project_breakdown: data,
        project_totalpercent: totalpercent,
      };

      if (formMode) {
        await addProject(project);
        // Send an email notification
        // await sendEmailNotification(project_name, project_description);
        // Increment the projects_counts for a cause
        await updateCause(causeDocId, {
          project_count: parseInt(projectCount + 1),
        });
        // Show a success message using SweetAlert
        Swal.fire("Success", "Project Added Successfully", "success");
        // toast.success("Project Added Successfully");
        // Clear the projects and reload the list
        setProjects([]);
        setLoading(true);
        getProjects().then((list) => {
          setProjects(list);
          setLoading(false);
        });
        // getlist();
        setOpen(false);
        setCauseName("");
        setCauseId("");
        setDonatedAmount(0);
        setProjectName("");
        setCountry("");
        setImage([]);
        setVideo([]);
        setProjectmilestone([]);
        setProjectDescription("");
        setProjectNGO("");
        setProjectContent("");
        setChangeContact("");
        setAmountGoal(0);
      } else {
        await updateProject(projectId, project);
        Swal.fire("Success", "Project Updated Successfully", "success");
        // toast.success("Project Updated Successfully");
        // Clear the projects and reload the list
        setProjects([]);
        setLoading(true);
        getProjects().then((list) => {
          setProjects(list);
          setLoading(false);
        });
        // getlist();
        setOpen(false);
        setCauseName("");
        setCauseId("");
        setDonatedAmount(0);
        setProjectName("");
        setCountry("");
        setImage([]);
        setVideo([]);
        setProjectmilestone([]);
        setProjectDescription("");
        setProjectNGO("");
        setProjectContent("");
        setChangeContact("");
        setAmountGoal(0);
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred: " + error.message, "error");
      // toast.error("addProjectHandler", error.message);
    }
  };

  // var nodemailer = require('nodemailer');

  // Function to send an email notification
  // async function sendEmailNotification(project_name, project_description) {
  //   console.log(project_name, project_description)
  //   var transporter = nodemailer.createTransport({
  //     host: "sandbox.smtp.mailtrap.io",
  //     port: 2525,
  //     auth: {
  //       user: "53a6a6c4ac7ff2",
  //       pass: "46f430ee714ccb"
  //     }
  //   });

  //   const mailOptions = {
  //     from: 'himani.vnnovate@gmail.com', // Sender's email address
  //     to: 'vasik.vnnovate@gmail.com', // Recipient's email address
  //     subject: 'New Project Added',
  //     text: `A new project has been added: ${project_name}\n\nDescription: ${project_description}`,
  //   };
  //   try {
  //     await transporter.sendMail(mailOptions);
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //   }
  // }

  const handleModal = (desc) => {
    setModal(true);
    setProjectDesc(desc);
  };

  const handleSetProjectName = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  const handleCompletedProjects = () => {
    setLoading(true);
    if (projectBtnName === "Show All Projects") {
      // Fetch all projects
      setProjects([]);
      getProjects()
        .then((list) => {
          setProjects(list);
          setProjectBtnName("Completed Project");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } else {
      // Fetch completed projects
      setProjects([]);
      getProjects("completed")
        .then((list) => {
          setProjects(list);
          setProjectBtnName("Show All Projects");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  // const getOneProjectView = async (projectId, status) => {
  //   Swal.fire({
  //     title: 'Are you sure you want to hide this project?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, hide it!'
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         await updateProjectHide(projectId,status === 0 ? 1 : 0);
  //         Swal.fire(
  //             'Hide!',
  //             'The project has been hidden.',
  //             'success'
  //         );
  //         getlist();
  //       } catch (error) {
  //         Swal.fire(
  //             'Error',
  //             'An error occurred while hiding the project.',
  //             'error'
  //         );
  //       }
  //     }
  //   });
  // };
  const getOneProjectView = async (projectId, status) => {
    const actionText = status === 0 ? "show" : "hide";

    Swal.fire({
      title: `Are you sure you want to ${actionText} this project?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${actionText} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateProjectHide(projectId, status === 0 ? 1 : 0);
          Swal.fire(
            `${actionText}!`,
            `The project has been ${actionText}ed.`,
            "success"
          );
          // Clear the projects and reload the list
          setProjects([]);
          setLoading(true);
          getProjects().then((list) => {
            setProjects(list);
            setLoading(false);
          });
        } catch (error) {
          Swal.fire(
            "Error",
            `An error occurred while ${actionText}ing the project.`,
            "error"
          );
        }
      }
    });
  };

  const isCompeleteProject = async (projectId, status) => {
    console.log(status, "project", projectId);
    const actionText = status === true ? "In-Process" : "Complete";

    Swal.fire({
      title: `Are you sure you want to ${actionText} this project?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${actionText} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateProjectStatus(
            projectId,
            status === false || status === undefined ? true : false
          );
          Swal.fire(
            `${actionText}!`,
            `The project has been ${actionText}ed.`,
            "success"
          );
          // Clear the projects and reload the list
          setProjects([]);
          setLoading(true);
          getProjects().then((list) => {
            setProjects(list);
            setLoading(false);
          });
        } catch (error) {
          Swal.fire(
            "Error",
            `An error occurred while ${actionText}ing the project.`,
            "error"
          );
        }
      }
    });
  };

  const toggleDropdown = (projectId) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId
          ? { ...project, dropdown: !project.dropdown }
          : project
      )
    );
  };

  useEffect(() => {
    getlist();
  }, []);

  const handleDonationAmount = () => {
    let payload = {
      donationId: userdetail.donation[0].donation_id,
      userName: userdetail.donation[0].user_name,
      amount: userdetail.donation[0].amount,
      projectId: userdetail.donation[0].project_id,
      projectName: userdetail.donation[0].project_name,
      currentTimestamp: userdetail.donation[0].created_at,
      StripTransactionId: stripid,
    };
  };

  useEffect(() => {
    projects.map((item, index) => {
      item?.donation?.length &&
        item.donation.map((value, index) => {
          setDonationAmount(value.amount);
        });
    });
  }, [projects]);

  return (
    <div className={classes.container}>
      <ToastContainer />
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Project Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{projectDesc && projectDesc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <TableContainer component={Paper}>
        <Grid container>
          <Grid item xs={8}>
            <Typography className={classes.title} variant="h6" component="div">
              All Projects
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCompletedProjects}
              className={classes.button}
            >
              {projectBtnName}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              className={classes.button}
              startIcon={<AddCircle />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                #
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Cause
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Funding Goal
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Donated
              </TableCell>
              <TableCell className={classes.head} style={{ width: "10%" }}>
                Name
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Location
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Image
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Video ID
              </TableCell>
              <TableCell className={classes.head} style={{ width: "35%" }}>
                Goal
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                Status
              </TableCell>
              <TableCell className={classes.head} style={{ width: "5%" }}>
                NGO & Location
              </TableCell>
              <TableCell className={classes.head} style={{ width: "10%" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <ScaleLoader
                    css={override}
                    size={150}
                    color={"#eb4034"}
                    loading={loading}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {projects.map((project) => (
                  <>
                    <TableRow key={project.id}>
                      <TableCell style={{ width: "5%" }}>
                        {(project.donation && project.donation.length > 0) ||
                        (project.project_breakdown &&
                          project.project_breakdown.length > 0) ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => toggleDropdown(project.id)}
                          >
                            {project.dropdown ? (
                              <RemoveCircleIcon />
                            ) : (
                              <AddCircleIcon />
                            )}
                          </IconButton>
                        ) : (
                          "NA"
                        )}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.cause_display_name}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.amount}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.donated_amount}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {project.project_name}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.country}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <img
                          src={project.image[0]}
                          alt={project.cause_display_name}
                          width="100"
                        />
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.youtube_video_id}
                      </TableCell>
                      <TableCell style={{ width: "35%" }}>
                        {project.project_description}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {project.project_status ? "Completed" : "In Process"}
                      </TableCell>

                      <TableCell style={{ width: "5%" }}>
                        {project.project_ngo_location}
                      </TableCell>
                      {/*<TableCell className={classes.goalColumn}>*/}
                      {/*  {*/}
                      {/*    getShortDescription(project.project_description, 50)*/}
                      {/*      .text*/}
                      {/*  }*/}
                      {/*  <span*/}
                      {/*    style={{*/}
                      {/*      textDecoration: "underline",*/}
                      {/*      cursor: "pointer",*/}
                      {/*    }}*/}
                      {/*    onClick={() =>*/}
                      {/*      handleModal(project.project_description)*/}
                      {/*    }*/}
                      {/*  >*/}
                      {/*    {getShortDescription(project.project_description, 50)*/}
                      {/*      .wordLength && "read more"}*/}
                      {/*  </span>*/}
                      {/*</TableCell>*/}

                      {/*<TableCell>{project.project_ngo_location}</TableCell>*/}
                      <TableCell style={{ width: "10%" }}>
                        <Tooltip
                          title={
                            project.project_status == true
                              ? "Completed"
                              : "In-Process"
                          }
                        >
                          <IconButton
                            onClick={() =>
                              isCompeleteProject(
                                project.id,
                                project.project_status
                              )
                            }
                            color="warning"
                            aria-label="View project"
                          >
                            {project.project_status !== true ? (
                              <CheckCircleOutline />
                            ) : (
                              <CheckCircle />
                            )}
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          onClick={() =>
                            getOneProjectView(project.id, project.project_show)
                          }
                          color="warning"
                          aria-label="View project"
                        >
                          {project.project_show === 1 ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            getOneProject(project.id, project.project_status)
                          }
                          color="primary"
                          aria-label="update project"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteHandler(project.id)}
                          color="secondary"
                          aria-label="delete project"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {project.donation.length > 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={12}
                        >
                          <Collapse
                            in={project.dropdown}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div className="justify-content-between">
                              {project.donation &&
                                project.donation.length > 0 && (
                                  <Box className="w-100" sx={{ margin: 1 }}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                      align="center"
                                    >
                                      Donation
                                    </Typography>
                                    {project.donation &&
                                      project.donation.length > 0 && (
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Username</TableCell>
                                              <TableCell align="right">
                                                Amount
                                              </TableCell>
                                              <TableCell align="right">
                                                Action
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {project.donation.map(
                                              (donation) => (
                                                <TableHeadRow>
                                                  <TableCell>
                                                    {donation.user_name}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {" "}
                                                    {donation.amount
                                                      ? `$${donation.amount}`
                                                      : 0}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setDonationPopup(true);
                                                      setuserDetails(project);
                                                    }}
                                                  >
                                                    <AddCircle />
                                                    Refund
                                                  </TableCell>
                                                </TableHeadRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      )}
                                  </Box>
                                )}
                              {project.donation &&
                              project.donation.length > 0 &&
                              project.project_breakdown &&
                              project.project_breakdown.length > 0 ? (
                                <Divider
                                  style={{ background: "black" }}
                                  orientation="vertical"
                                  flexItem
                                />
                              ) : (
                                ""
                              )}
                              {project.project_breakdown &&
                                project.project_breakdown.length > 0 && (
                                  <Box className="w-100" sx={{ margin: 1 }}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                      align="center"
                                    >
                                      Project Breakdown
                                    </Typography>
                                    {project.project_breakdown &&
                                      project.project_breakdown.length > 0 && (
                                        <Table
                                          size="small"
                                          aria-label="purchases"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Cost</TableCell>
                                              <TableCell>Amount</TableCell>
                                              <TableCell>Percent</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {project.project_breakdown.map(
                                              (project_breakdown) => (
                                                <TableHeadRow>
                                                  <TableCell>
                                                    {project_breakdown.cost
                                                      ? project_breakdown.cost.replace(
                                                          /(<([^>]+)>)/gi,
                                                          ""
                                                        )
                                                      : 0}
                                                  </TableCell>
                                                  <TableCell>
                                                    {project_breakdown.amount
                                                      ? `$${project_breakdown.amount}`
                                                      : 0}
                                                  </TableCell>
                                                  <TableCell>
                                                    {project_breakdown.percent
                                                      ? `${project_breakdown.percent}%`
                                                      : 0}
                                                  </TableCell>
                                                </TableHeadRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      )}
                                  </Box>
                                )}
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectDialog
        open={open}
        close={handleClose}
        formmode={formMode}
        causeDisplayName={causeDisplayName}
        cause_id={cause_id}
        causes={causes}
        donated_amount={donated_amount}
        project_name={project_name}
        country={country}
        image={image}
        project_milestones={project_milestones}
        youtube_video_id={youtube_video_id}
        inputFields={inputFields}
        inputimageFields={inputimageFields}
        addmorefield={addmorefield}
        project_description={project_description}
        project_ngo_location={project_ngo_location}
        project_content={project_content}
        contact={contact}
        amount={amount}
        newTotalCost={projectTotalPercent}
        project_detail={projectDetail}
        changeCauseName={handleCauseName}
        changeDonatedAmount={handleDonatedAmount}
        changeProjectName={handleProjectName}
        changeCountry={handleCountry}
        changeImage={handleImage}
        changeVideo={handleVideo}
        changeProjectDescription={handleProjectDescription}
        changeProjectNGO={handleProjectNGO}
        changeProjectContent={handleProjectContent}
        changeContact={handleContact}
        changeAmountGoal={handleAmountGoal}
        changerowsize={addTableRows}
        addrowfield={addInputField}
        handleDeleteImage={handleDeleteImage}
        changeInputField={addMoreInputFields}
        changeMoreImageField={addMoreimageInputFields}
        changemileStone={handleMoreMilestone}
        addProject={(data, totalpercent) =>
          addProjectHandler(data, totalpercent)
        }
        deleteVideo={deleteVideo}
        projectStatus={projectStatus}
      />
      {donationPopup && (
        <Modal
          show={donationPopup}
          onHide={() => {
            setDonationPopup(false);
            setuserDetails(null);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {" "}
          <ValidatorForm onSubmit={handleDonationAmount}>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Refund
              </Modal.Title>
              <Modal.Title id="contained-modal-title-vcenter">
                Donation Amount:{donationAmount}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body sx={{ marginTop: "10px" }}>
              <TextValidator
                label="Refund Amount"
                fullWidth
                onChange={(e) => setRefundAmount(e.target.value)}
                variant="outlined"
                margin="normal"
                name="refundAmount"
                value={refundAmount}
                validators={["required"]}
                errorMessages={["this field is required"]}
                autoComplete="off"
                type="number"
                InputProps={{
                  inputProps: {
                    max: donationAmount,
                    min: 0,
                  },
                }}
              />
              <TextValidator
                label="Strip Transaction"
                fullWidth
                onChange={(e) => setStripId(e.target.value)}
                variant="outlined"
                margin="normal"
                name="refundAmount"
                value={stripid}
                validators={["required"]}
                errorMessages={["this field is required"]}
                autoComplete="off"
                type="text"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setDonationPopup(false);
                  setuserDetails(null);
                }}
              >
                Close
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Modal.Footer>
          </ValidatorForm>
        </Modal>
      )}
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: "4rem",
    padding: "2rem",
  },
  title: {
    flex: "1 1 100%",
    padding: "20px",
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
  goalColumn: {
    // Set your desired width for the "Goal" column
    minWidth: 250,
  },
}));

export default Project;