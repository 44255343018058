import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { ScaleLoader } from 'react-spinners';
import {
    Button, Typography, Grid, Container, makeStyles,
} from '@material-ui/core';
import { firestore } from '../firebase'


function Donation() {

    const classes = useStyles();
    const [donations, setDonations] = useState([]);
    const [filteredDonation, setFilteredDonation] = useState([]);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const columns = ["ID", "User Name", "Cause Name", "Project Name", "Amount", "Created At", "Updated At"];

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15, 20],
        selectableRows: "none",
    };

    const override = `
        display: flex;
        align-items: center;
        justify-content: center;    
        border-color: red;
    `;

    const getDonations = async () => {
        try {
            setIsLoading(true);
            const data = await firestore.collection('donations').get();
            let array = [];
            data.forEach(doc => {
                array.push(doc.data());
            });
            setDonations(array);
            setFilteredDonation(array);
            setIsLoading(false);
            // return array;
        } catch (error) {
            throw error;
        }
    }

    const handleChange = (field, value) => {
        console.log(field, value, "handleChange");
        if (field === "startDate") {
            setIsLoading(true);
            setFilteredDonation([]);
            let current_donations = [...donations];
            let startDateSeconds = new Date(value).getTime();
            if (endDate !== '') {
                if (new Date(value).getTime() > new Date(endDate).getTime()) {
                    alert("Please select start date before end date!");
                    setStartDate('');
                } else {
                    setStartDate(value);
                    let endDateSeconds = new Date(endDate).getTime();
                    for (let i = 0; i < current_donations.length; i++) {
                        if (current_donations[i].created_at?.seconds * 1000 > startDateSeconds && current_donations[i].created_at?.seconds * 1000 < endDateSeconds) {
                            setFilteredDonation(filteredDonation => [...filteredDonation, current_donations[i]]);
                        }
                    }
                }
            } else {
                setStartDate(value);
                for (let i = 0; i < current_donations.length; i++) {
                    if (current_donations[i].created_at?.seconds * 1000 > startDateSeconds) {
                        setFilteredDonation(filteredDonation => [...filteredDonation, current_donations[i]]);
                    }
                }
            }
        }
        if (field === "endDate") {
            setIsLoading(true);
            let current_donations = [...donations];
            let endDateSeconds = new Date(value).getTime();
            setFilteredDonation([]);
            if (startDate !== '') {
                if (new Date(value).getTime() < new Date(startDate).getTime()) {
                    alert("Please select end date after start date!");
                    setEndDate('');
                } else {
                    setEndDate(value);
                    let startDateSeconds = new Date(startDate).getTime();
                    for (let i = 0; i < current_donations.length; i++) {
                        if (current_donations[i].created_at?.seconds * 1000 < endDateSeconds && current_donations[i].created_at?.seconds * 1000 > startDateSeconds) {
                            setFilteredDonation(filteredDonation => [...filteredDonation, current_donations[i]]);
                        }
                    }
                }
            } else {
                setEndDate(value);
                for (let i = 0; i < current_donations.length; i++) {
                    if (current_donations[i].created_at?.seconds * 1000 > endDateSeconds) {
                        setFilteredDonation(filteredDonation => [...filteredDonation, current_donations[i]]);
                    }
                }
            }
        }
        if (field === "clearDate") {
            setIsLoading(true);
            setStartDate('');
            setEndDate('');
            setFilteredDonation(donations);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setStartDate('');
        setEndDate('');
        getDonations();
    }, []);

    function timestampDateConverter(timesTamp) {
        const _date = timesTamp?.toDate();
        const day = _date === undefined ? 0 : _date?.getDay();
        const month = _date === undefined ? 0 : _date?.getMonth();
        const year = _date === undefined ? 0 : _date?.getFullYear();
        return `${day}/${month + 1}/${year}`
    }

    return (
        <Container>
            <div style={{ marginTop: '100px' }} />
            <Grid container>
                <Grid item xs={6}>
                    <Typography className={classes.title} variant="h6" component="div">
                        Donations
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className="display-flex datefilter">
                        <p> From : &nbsp;</p>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => handleChange("startDate", e.target.value)}
                        />
                        &nbsp;
                        &nbsp;
                        <p> To : &nbsp;</p>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => handleChange("endDate", e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleChange("clearDate", null)}
                            className={classes.button}
                            disabled={startDate !== '' || endDate !== '' ? false : true}
                        >Clear Filter</Button>
                    </div>
                </Grid>
            </Grid>
            {
                isLoading ? (<ScaleLoader
                    css={override}
                    size={150}
                    color={"#eb4034"} />
                ) : (<MUIDataTable
                    data={filteredDonation.map((item, index) => {
                        return [
                            index + 1,
                            item.user_name,
                            item.cause_display_name,
                            item.project_name,
                            `$` + item.amount,
                            timestampDateConverter(item.created_at),
                            timestampDateConverter(item.updated_at)
                        ]
                    })}
                    columns={columns}
                    options={options}
                />)
            }

        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    dateRangePicker: {
        border: "4px gray solid",
        borderRadius: "10px",
        zIndex: 999,
        position: "absolute"
    },
    title: {
        flex: '1 1 100%',
        padding: '10px'
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
}));

export default Donation