import React, { } from "react";
import "./Table.css"

export default function Header({ column: { label } }) 
{
    return (
        <div className='th' style={{ display: "inline-flex" }} >
            <div className='th-content' >
                {label}
            </div>
        </div>
    )
}