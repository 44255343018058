class Project {
    constructor(
      id,
      cause_display_name,
      cause_id,
      donated_amount,
      project_name,
      country,
      image,
      youtube_video_id,
      project_description,
      project_ngo_location,
      project_show,
      project_status,
      amount,
      dropdown,
      dontation,
      project_breakdown,
      project_milestones,
    ) {
      this.id = id;
      this.cause_display_name = cause_display_name;
      this.cause_id = cause_id;
      this.amount = amount;
      this.donated_amount = donated_amount;
      this.project_name = project_name;
      this.country = country;
      this.image = image;
      this.youtube_video_id = youtube_video_id;
      this.project_description = project_description;
      this.project_ngo_location = project_ngo_location;
      this.project_show = project_show;
      this.project_status = project_status;
      this.dropdown = dropdown;
      this.donation = dontation;
      this.project_breakdown = project_breakdown;
      this.project_milestones = project_milestones;
    }
  }

  export default Project;
