import firebase from "../firebase";
import Project from "../models/project";
import { salesforceBackURL } from "../secrets";

import axios from "axios";

const firestore = firebase.firestore();

// export const getProjects = async () => {
//   try {
//     const data = await firestore.collection("projects").get();
//     let donationArray = [];

//     let array = [];
//     let dropdown = false;
//     console.log(data);
//     data.forEach(async (doc) => {
//       // try {
//       //   let donation = await firestore
//       //     .collection("donations")
//       //     .where("project_id", "==", "n2DfJwwvIMupGFh85Pjg")
//       //     .get();
//       //   donation.docs.length &&
//       //     donation.docs.map((doc) => {
//       //       donationArray.push(doc.data());
//       //     });
//       // } catch (error) {
//       //   console.log(error);
//       // }
//       let image = doc.data().project_assets?.image;
//       let video = doc.data().project_assets?.youtube_video_id;
//       let donation = donationArray.length ? donationArray : [];
//       const project = new Project(
//         doc.id,
//         doc.data().cause_display_name,
//         doc.data().cause_id,
//         doc.data().donated_amount,
//         doc.data().project_name,
//         doc.data().project_location.country,
//         image,
//         video,
//         doc.data().project_description,
//         doc.data().project_ngo_location,
//         doc.data().project_funding_goal.amount,
//         dropdown,
//         donation
//       );
//       array.push(project);
//     });
//     return array;
//   } catch (error) {
//     throw error;
//   }
// };

export const getProjects = async (status) => {
  try {
    let data;
    if (status) {
      data = await firestore
        .collection("projects")
        .where("project_status", "==", true)
        .get();
    } else {
      data = await firestore.collection("projects").get();
    }

    let array = [];

    for (const doc of data.docs) {
      const projectId = doc.id;
      let image = doc.data().project_assets?.image;
      let video = doc.data().project_assets?.youtube_video_id;

      let donationArray = [];
      try {
        const donation = await firestore
          .collection("donations")
          // .where("project_id", "==", "n2DfJwwvIMupGFh85Pjg")
          .where("project_id", "==", doc.id)
          .get();

        donation.forEach((doc) => {
          donationArray.push(doc.data());
        });
      } catch (error) {
        console.log(error);
      }
      // console.log(doc.data())

      const project = new Project(
        projectId,
        doc.data().cause_display_name,
        doc.data().cause_id,
        doc.data().donated_amount,
        doc.data().project_name,
        doc.data().project_location.country,
        image,
        video,
        doc.data().project_description,
        doc.data().project_ngo_location,
        doc.data().project_show,
        doc.data().project_status,
        doc.data().project_funding_goal.amount,
        false,
        donationArray,
        doc.data().project_breakdown
      );
      array.push(project);
    }
    return array;
  } catch (error) {
    throw error;
  }
};

export const addProject = async (project) => {
  try {
    console.log("Admin Project : ", project);
    await firestore.collection("projects").doc().set(project);

    let query_newProject = await firestore
      .collection("projects")
      .where("cause_id", "==", project.cause_id)
      .where("project_name", "==", project.project_name)
      .get();

    if (query_newProject.docs.length > 0) {
      if (
        query_newProject.docs[0].data() !== null &&
        query_newProject.docs[0].data() !== undefined
      ) {
        let newProject = query_newProject.docs[0].data();

        newProject["id"] = query_newProject.docs[0].id;
        await axios.post(
          salesforceBackURL + "addNewProject",
          JSON.parse(JSON.stringify(newProject))
        );
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getProject = async (id) => {
  try {
    const project = await firestore.collection("projects").doc(id);
    const data = await project.get();
    return data.data();
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (id, data) => {
  try {
    await firestore.collection("projects").doc(id).update(data);
    await axios.post(salesforceBackURL + "updateProject", {
      id: id,
      data: data,
    });
  } catch (error) {
    throw error;
  }
};

export const updateProjectHide = async (id, status) => {
  try {
    await firestore.collection("projects").doc(id).update({
      project_show: status,
    });
  } catch (error) {
    throw error;
  }
};

export const updateProjectStatus = async (id, status) => {
  try {
    await firestore.collection("projects").doc(id).update({
      project_status: status,
    });
  } catch (error) {
    throw error;
  }
};
export const deleteProject = async (id) => {
  try {
    await firestore.collection("projects").doc(id).delete();
    await axios.post(salesforceBackURL + "deleteProject", { id: id });
  } catch (error) {
    throw error;
  }
};
