import React, {useEffect, useState} from 'react';
import {
    // Table, TableBody, TableCell, TableRow, TableHead,
    Typography, Button, Grid, IconButton, TableContainer, Paper, makeStyles, Container,
} from '@material-ui/core';
import {AddCircle, Edit, Delete} from '@material-ui/icons';
import {ScaleLoader} from 'react-spinners';
import {ToastContainer, toast} from 'react-toastify';
import {getCustomers, addCustomer, getCustomer, updateCustomer, deleteCustomer} from '../data/customerData';
import CustomerDialog from './CustomerDialog';
import MUIDataTable from "mui-datatables";

const Customer = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [formMode, setFormMode] = useState(true);
    const [custId, setCustId] = useState('');
    const [user_name, setName] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [user_email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState();
    const [country, setCountry] = useState('');
    // const [firstName, setFirstName] = useState('first name');
    // const [lastName, setLastName] = useState('last name');
    // const [user_email, setEmail] = useState('test@gmail.com');
    // const [phone_number, setPhoneNumber] = useState(1234567890);
    // const [country, setCountry] = useState('united state');

    const override = `
        display: flex;
        align-items: center;
        justify-content: center;    
        border-color: red;
    `;

    const columns = ["ID", "User Name", "Email", "Phone", "Country", "Edit", "Delete"];
    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15, 20],
        selectableRows: false
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleName = (event) => {
        setName(event.target.value);
    }

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastName = (event) => {
        setLastName(event.target.value);
    }
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneNumber = (event) => {
        const phoneNumber = parseInt(event.target.value)
        setPhoneNumber(phoneNumber)
    }

    const handleCountry = (event) => {
        setCountry(event.target.value);
    }

    const getlist = async () => {
        try {
            setLoading(true);
            const list = await getCustomers();
            setUsers(list);
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const getOneCustomer = async (id) => {
        try {
            setFormMode(false);
            setCustId(id);
            const response = await getCustomer(id);
            setName(response.user_name);
            setFirstName(response.firstName);
            setLastName(response.lastName)
            setEmail(response.user_email);
            setPhoneNumber(response.phone_number);
            setCountry(response.country);
            setOpen(true);
        } catch (error) {
            toast.error(error.message);
        }

    }

    const deleteHandler = async (id) => {
        try {
            await deleteCustomer(id);
            getlist();
            toast.success('User Deleted Successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleAdd = () => {
        setOpen(true);
        setFormMode(true);
        setName('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber(0);
        setCountry('');
    }

    const addCustomerHandler = async () => {
        try {
            const customer = {
                user_name,
                firstName,
                lastName,
                user_email,
                phone_number,
                country
            }
            if (formMode) {
                await addCustomer(customer);
                toast.success('User Added Successfully');
                getlist();
                setOpen(false);
                setName('');
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber(0);
                setCountry('');

            } else {
              await updateCustomer(custId, customer);
              
                toast.success('User Updated Successfully');
                getlist();
                setOpen(false);
                setName('');
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber(0);
                setCountry('');
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        getlist();
    }, []);

    return (
        <Container className={classes.container}>
            <ToastContainer />
            <TableContainer component={Paper}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography className={classes.title} variant="h6" component="div">
                            All Users
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            className={classes.button}
                            startIcon={<AddCircle />}
                        >Add</Button>
                    </Grid>
                </Grid>
                {/* <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head}>User Name</TableCell>
                            <TableCell className={classes.head}>Email</TableCell>
                            <TableCell className={classes.head}>Phone</TableCell>
                            <TableCell className={classes.head}>Country</TableCell>
                            <TableCell className={classes.head}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <ScaleLoader
                                        css={override}
                                        size={150}
                                        color={"#eb4034"}
                                        loading={loading} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {users.map((cust) => (
                                    <TableRow key={cust.id}>
                                        <TableCell>{cust.user_name}</TableCell>
                                        <TableCell>{cust.user_email}</TableCell>
                                        <TableCell>{cust.phone_number}</TableCell>
                                        <TableCell>{cust.country}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => getOneCustomer(cust.id)} color="primary" aria-label="update user">
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => deleteHandler(cust.id)} color="secondary" aria-label="delete user">
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </>
                        )}
                    </TableBody>
                </Table> */}
                {users.length === 0 ? (
                    <ScaleLoader
                        css={override}
                        size={150}
                        color={"#eb4034"}
                        loading={loading} />
                ) : (<MUIDataTable
                    data={users.map((item, index) => {
                        return [
                            index + 1,
                            item.user_name,
                            item.user_email,
                            item.phone_number,
                            item.country,
                            <IconButton onClick={() => getOneCustomer(item.id)} color="primary" aria-label="update user">
                                <Edit />
                            </IconButton>,
                            <IconButton onClick={() => deleteHandler(item.id)} color="secondary" aria-label="delete user">
                                <Delete />
                            </IconButton>
                        ]
                    })}
                    columns={columns}
                    options={options}
                />)}
            </TableContainer>
            <CustomerDialog
                open={open}
                close={handleClose}
                formmode={formMode}
                user_name={user_name}
                firstName={firstName}
                lastName={lastName}
                user_email={user_email}
                phone_number={phone_number}
                country={country}
                changeName={handleName}
                changeFirstName={handleFirstName}
                changeLastName={handleLastName}
                changeEmail={handleEmail}
                changePhoneNumber={handlePhoneNumber}
                changeCountry={handleCountry}
                addCustomer={addCustomerHandler}
            />
        </Container>
    );
}


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    container: {
        marginTop: '100px'
    },
    title: {
        flex: '1 1 100%',
        padding: '10px'
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
}));

export default Customer;