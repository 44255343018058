import React, { useEffect, useState } from "react"
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	TableContainer,
	Paper,
	makeStyles,
	Container,
	Typography,
	Button,
	Grid,
	IconButton,
} from "@material-ui/core"
import { AddCircle, Edit, Delete } from "@material-ui/icons"
import { ScaleLoader } from "react-spinners"
import { ToastContainer, toast } from "react-toastify"
import {
	getCauses,
	addCause,
	getCause,
	updateCause,
	deleteCause,
} from "../data/causeData"
import CauseDialog from "./CauseDialog"
import firebase from "../firebase"

const Cause = () => {
	const classes = useStyles()
	const [causes, setCauses] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [formMode, setFormMode] = useState(true)
	const [catId, setCatId] = useState("")
	const [cause_display_name, setCauseName] = useState("")
	const [icon, setIcon] = useState("")
	const [project_count, setProject] = useState(0)
	const override = `
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: red;
    `
	const handleClose = () => {
		setOpen(false)
	}

	const handleCauseName = (event) => {
		setCauseName(event.target.value)
	}
	const handleIcon = async (event) => {
		if (!event.target.files.length) return

		const file = event.target.files[0]

		const storageRef = firebase.storage().ref()
		const iconsRef = storageRef.child(`icons/causes/${file.name}`)
		const snapshot = await iconsRef.put(file)
		const downloadUrl = await snapshot.ref.getDownloadURL()
		console.log(downloadUrl)

		setIcon(downloadUrl.toString())
	}
	const handleProject = (event) => {
		const projectCount = parseInt(event.target.value)

		setProject(projectCount)
	}

	const getlist = async () => {
		try {
			setLoading(true)
			const list = await getCauses()
			setCauses(list)
			setLoading(false)
		} catch (error) {
			toast.error(error.message)
			setLoading(false)
		}
	}
	const getOneCause = async (id) => {
		try {
			setFormMode(false)
			setCatId(id)
			const response = await getCause(id)
			setCauseName(response.cause_display_name)
			setIcon(response.icon)
			setProject(response.project_count)
			setOpen(true)
		} catch (error) {
			toast.error(error.message)
		}
	}
	const deleteHandler = async (id) => {
		try {
			await deleteCause(id)
			getlist()
			toast.success("Cause Deleted Successfully")
		} catch (error) {
			toast.error(error.message)
		}
	}
	const handleAdd = () => {
		setOpen(true)
		setFormMode(true)
		setCauseName("")
		setIcon("")
		setProject(0)
	}

	const addCauseHandler = async () => {
		try {
			const cause = {
				cause_display_name,
				icon,
				project_count,
			}
			if (formMode) {
				await addCause(cause)
				toast.success("Cause Added Successfully")
				getlist()
				setOpen(false)
				setCauseName("")
				setIcon("")
				setProject(0)
			} else {
				await updateCause(catId, cause)
				toast.success("Cause Updated Successfully")
				getlist()
				setOpen(false)
				setCauseName("")
				setIcon("")
				setProject(0)
			}
		} catch (error) {
			toast.error(error.message)
		}
	}

	useEffect(() => {
		getlist()
	}, [])
	return (
		<Container className={classes.container}>
			<ToastContainer />
			<TableContainer component={Paper}>
				<Grid container>
					<Grid item xs={8}>
						<Typography className={classes.title} variant="h6" component="div">
							All Causes
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="contained"
							color="primary"
							onClick={handleAdd}
							className={classes.button}
							startIcon={<AddCircle />}
						>
							Add
						</Button>
					</Grid>
				</Grid>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.head}>Cause Name</TableCell>
							<TableCell className={classes.head}>Icon</TableCell>
							<TableCell className={classes.head}>Project Count</TableCell>
							<TableCell className={classes.head}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{causes.length === 0 ? (
							<TableRow>
								<TableCell colSpan={4}>
									<ScaleLoader
										css={override}
										size={150}
										color={"#eb4034"}
										loading={loading}
									/>
								</TableCell>
							</TableRow>
						) : (
							<>
								{causes.map((cause) => (
									<TableRow key={cause.id}>
										<TableCell>{cause.cause_display_name}</TableCell>
										<TableCell>
											<img
												src={cause.icon}
												alt={cause.cause_display_name}
												width="100"
											/>
										</TableCell>
										<TableCell>{cause.project_count}</TableCell>

										<TableCell>
											<IconButton
												onClick={() => getOneCause(cause.id)}
												color="primary"
												aria-label="update cause"
											>
												<Edit />
											</IconButton>
											<IconButton
												onClick={() => deleteHandler(cause.id)}
												color="secondary"
												aria-label="delete cause"
											>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<CauseDialog
				open={open}
				close={handleClose}
				formmode={formMode}
				cause_display_name={cause_display_name}
				icon={icon}
				project_count={project_count}
				changeCauseName={handleCauseName}
				changeIcon={handleIcon}
				changeProject={handleProject}
				addCause={addCauseHandler}
			/>
		</Container>
	)
}

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	container: {
		marginTop: "100px",
	},
	title: {
		flex: "1 1 100%",
		padding: "20px",
	},
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	button: {
		margin: theme.spacing(1),
		float: "right",
	},
}))

export default Cause