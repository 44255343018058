class Customer {
    constructor(id, user_name, firstName, lastName, user_email, phone_number, country
        ){
        this.id = id;
        this.user_name= user_name;
        this.firstName= firstName;
        this.lastName= lastName;
        this.user_email = user_email;
        this.phone_number = phone_number;
        this.country = country;
        
    }
}

export default Customer;