class Cause {
	constructor({ id, cause_display_name, icon, project_count, cause_id }) {
		this.id = id
		this.cause_display_name = cause_display_name
		this.icon = icon
		this.project_count = project_count
		this.cause_id = cause_id
	}
}

export default Cause
