import React from "react"

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Grid,
	Input,
} from "@material-ui/core"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"

const CauseDialog = (props) => {
	return (
		<Dialog
			fullWidth={true}
			maxWidth="lg"
			open={props.open}
			onClose={props.close}
			aria-labelledby="max-width-dialog-title"
		>
			<DialogTitle>{props.formmode ? "Add New" : "Update"} Cause</DialogTitle>
			<ValidatorForm onSubmit={props.addCause}>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Cause Name"
								onChange={props.changeCauseName}
								name="cause_display_name"
								value={props.cause_display_name}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Project Count"
								onChange={props.changeProject}
								name="project_count"
								value={props.project_count}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
								type="number"
								InputProps={{
									inputProps: {
										max: 100,
										min: 0,
									},
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Input
								accept="image/*"
								hidden
								name="image"
								type="file"
								onChange={props.changeIcon}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="submit" color="secondary">
						{props.formmode ? "Add" : "Update"}
					</Button>
					<Button onClick={props.close} color="primary">
						Close
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	)
}

export default CauseDialog