import React, { Component } from 'react'
import { Box, Container, Button, Typography, Grid } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Link } from "react-router-dom";
// import axios from "axios";
// import qs from 'qs';
// import { salesforceSecrets, salesforceAuth } from "../secrets";


class Dashboard extends Component {

    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    bgcolor="white"
                    boxShadow="2"
                    borderRadius="12px"
                    textAlign="center"
                    p="24px"
                    mt="70px">
                    <Typography variant="h5" gutterBottom>Welcome to Caritas Console</Typography>
                    <br /><br />
                    <Typography>Please press the <span style={{ color: '#2057c0', fontWeight: 'bold' }}>CONTINUE</span> button to continue.</Typography>
                    <br />
                    <Grid container justifyContent="center">
                        <Link to="/customer"><Button variant="contained" color="primary">Continue</Button></Link>
                    </Grid>
                </Box>
            </Container>
        )
    }
}

export default Dashboard
