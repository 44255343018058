import firebase from "../firebase";
import Customer from "../models/customer";
import { salesforceBackURL } from "../secrets";
import axios from "axios";

const firestore = firebase.firestore();
// if (window.location.hostname === "localhost") {
//     firestore.useEmulator("localhost", 5002);
// }
export const getCustomers = async () => {
  try {
    const response = await firestore.collection("users");
    const data = await response.get();
    // const responseSalesforce = await axios.get(salesforceBackURL + "users");
    // data = [...data, responseSalesforce.data.data];
    // console.log(data, "data");

    // data.forEach((doc) => {
    //   console.log(doc);
    //   const user = new Customer(
    //     doc.id,
    //     doc.Name,
    //     doc.firstName__c,
    //     doc.lastName__c,
    //     doc.user_email__c,
    //     doc.phone_number__c,
    //     doc.country__c
    //   );
    // });
    // console.log(data, "data");
    let array = [];
    data.forEach((doc) => {
      // console.log(doc.data());
      const user = new Customer(
        doc.id,
        doc.data().user_name,
        doc.data().firstName,
        doc.data().lastName,
        doc.data().user_email,
        doc.data().phone_number,
        doc.data().country
      );

      array.push(user);
    });
    return array;
  } catch (error) {
    throw error;
  }
};

export const addCustomer = async (customer) => {
  try {
    await firestore.collection("users").doc().set(customer);
    let query_newCustomer = await firestore
      .collection("users")
      .where("user_email", "==", customer.user_email)
      .get();

    if (query_newCustomer.docs.length > 0) {
      if (
        query_newCustomer.docs[0].data() !== null &&
        query_newCustomer.docs[0].data() !== undefined
      ) {
        let newCustomer = query_newCustomer.docs[0].data();

        newCustomer["id"] = query_newCustomer.docs[0].id;
        await axios.post(
          salesforceBackURL + "addNewUser",
          JSON.parse(JSON.stringify(newCustomer))
        );
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getCustomer = async (id) => {
  try {
    const customer = await firestore.collection("users").doc(id);
    const data = await customer.get();
    return data.data();
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (id, data) => {
  try {
    await firestore.collection("users").doc(id).update(data);
    await axios.post(salesforceBackURL + "updateCustomer", {
      id: id,
      data: data,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteCustomer = async (id) => {
  try {
    await firestore.collection("users").doc(id).delete();
    await axios.post(salesforceBackURL + "deleteCustomer", { id: id });
  } catch (error) {
    throw error;
  }
};
