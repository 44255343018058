import {combineReducers} from "redux";

const DEFAULT_REDUCER = (initstate, action) => {
    return {
        key: "Hello World",
    }
}
const rootReducer = combineReducers({
    DEFAULT: DEFAULT_REDUCER
})

export default rootReducer;