import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./Auth/Login"
import Forgot from "./Auth/Forgot"
import Dashboard from './Pages/Dashboard'
import Customer from './Pages/Customer'
import Causes from './Pages/Cause'
import Projects from './Pages/Projects'
import Donation from './Pages/Donation'
import Authenticated from './Components/Authenticated'
import NavBar from './layouts/NavBar'
import "./App.css"



// Put any other imports below so that CSS from your
// components takes precedence over default styles.

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <Switch>
                <Route exact path="/" >
                    <Authenticated>
                        <Dashboard />
                    </Authenticated>
                </Route>
                <Route exact path="/login">
                    <Authenticated nonAuthenticated={true}>
                        <Login />
                    </Authenticated>
                </Route>
                <Route exact path="/forgot">
                    <Authenticated nonAuthenticated={true}>
                        <Forgot />
                    </Authenticated>
                </Route>
                <Route exact path="/customer" >
                    <Authenticated>
                        <Customer />
                    </Authenticated>
                </Route>
                <Route exact path="/causes" >
                    <Authenticated>
                        <Causes />
                    </Authenticated>
                </Route>
                <Route exact path="/projects" >
                    <Authenticated>
                        <Projects />
                    </Authenticated>
                </Route>
                <Route exact path="/donations" >
                    <Authenticated>
                        <Donation />
                    </Authenticated>
                </Route>
                <Route path="*" render={() => "404 Not found!"} />
            </Switch>
        </BrowserRouter>
    )
}

export default App
