import React from "react"

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Grid,
} from "@material-ui/core"
// import MuiPhoneNumber from "material-ui-phone-number";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator"

const CustomerDialog = (props) => {
	return (
		<Dialog
			fullWidth={true}
			maxWidth="lg"
			open={props.open}
			onClose={props.close}
			aria-labelledby="max-width-dialog-title"
		>
			<DialogTitle>{props.formmode ? "Add New" : "Update"} User</DialogTitle>
			<ValidatorForm onSubmit={props.addCustomer}>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="First Name"
								onChange={props.changeFirstName}
								name="firstName"
								value={props.firstName}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
                        <Grid item xs={6}>
							<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Last Name"
								onChange={props.changeLastName}
								name="lastName"
								value={props.lastName}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={6}>
							<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Email"
								onChange={props.changeEmail}
								name="user_email"
								value={props.user_email}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={6}>
						<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Phone Number"
								onChange={props.changePhoneNumber}
								name="phone_number"
								value={props.phone_number}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
						<Grid item xs={6}>
						<TextValidator
								variant="outlined"
								margin="normal"
								fullWidth
								label="Country"
								onChange={props.changeCountry}
								name="country"
								value={props.country}
								validators={["required"]}
								errorMessages={["this field is required"]}
								autoComplete="off"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="submit" color="secondary">
						{props.formmode ? "Add" : "Update"}
					</Button>
					<Button onClick={props.close} color="primary">
						Close
					</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	)
}

export default CustomerDialog