//firebase App is always required and must be listed first
import firebase from "firebase/compat/app";

//If you enabled Analytics in your project
import "firebase/analytics";

//Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
// connectFirestoreEmulator(getFirestore(), "localhost", 5002);
import "firebase/compat/storage";

import { firebaseSecrets } from "./secrets.js";

const config = {
  apiKey: firebaseSecrets.apiKey,
  authDomain: firebaseSecrets.authDomain,
  databaseURL: firebaseSecrets.databaseURL,
  projectId: firebaseSecrets.projectId,
  storageBucket: firebaseSecrets.storageBucket,
  messagingSenderId: firebaseSecrets.messagingSenderId,
  appId: firebaseSecrets.appId,
};

// firebase.initializeApp(config);
const firebaseApp = firebase.initializeApp(config);

export const firestore = firebase.firestore();
// if (window.location.hostname === "localhost") {
//     firestore.useEmulator("localhost", 5002);
// }
// Get a reference to the storage service, export it for use
// export const storageRef = firebase.storage().ref;

export const auth = firebaseApp.auth();
export default firebase;
