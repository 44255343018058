import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import {
	// Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Snackbar,
	// TextField,
} from "@material-ui/core";
//firebase integration
import firebase from "../firebase"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(-12),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: "capitalize",
		fontSize: "18px",
	},
}))

export default function Login(props) {
	const classes = useStyles()
	const [user_email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [emailError, setEmailError] = useState("")
	const [passwordError, setPasswordError] = useState("")

	useEffect(() => {
		const watcher = firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				firebase
					.firestore()
					.collection("users")
					.doc(`/${user.uid}`)
					.get()
					.then((querySnapshot) => {
						if (!querySnapshot.empty) {
							const data = querySnapshot.data()

							if (data.isAdmin) {
								props.history.replace("/")
							} else {
								setEmailError("Not Allowed!")
								firebase.auth().signOut()
							}
						} else {
							setEmailError("Not Allowed!")
							firebase.auth().signOut()
						}
					})
					.catch(console.table)
			}
		})
		return () => { watcher(); }
	}, [props.history])

	const handleEmailChange = (e) => {
		setEmail(e.target.value)
	}

	const handlePasswordChange = (e) => {
		setPassword(e.target.value)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault()
		login()
	}

	const login = () => {
		let valid_data = true

		setEmailError(null)
		setPasswordError(null)

		if (user_email === "") {
			setEmailError("Required!")
			valid_data = false
		}

		if (password === "") {
			setPasswordError("Required!")
			valid_data = false
		}

		if (valid_data) {
			firebase
				.auth()
				.signInWithEmailAndPassword(user_email, password)
				.catch((err) => {
					console.log("yeah", err?.message)
					if (err.code === "auth/wrong-password") {
						setPasswordError("Incorrect Password!")
					}
				})
		}
	}

	const [open, setOpen] = React.useState(false);
	//   const [mail, setMail] = React.useState("");
	const [toastOpen, setToastOpen] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleSubmit = async () => {
		console.log("sending email to: ", user_email);
		try {
			await firebase.auth().sendPasswordResetEmail(user_email);
			setOpen(false);
			setToastOpen(true);
			setToastMessage("Password reset email sent!");
		} catch (error) {
			setToastOpen(true);
			setToastMessage(error.message);
		}
	};

	const handleOnChange = (event) => {
		const user_email = event.target.value;
		setEmail(user_email);
	};

	const handleToastClose = () => {
		setToastOpen(false);
		setToastOpen(false);
	};


	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				bgcolor="white"
				boxShadow="2"
				borderRadius="12px"
				textAlign="center"
				p="24px"
				mt="70px">
				<img
					alt="logo"
					src="https://firebasestorage.googleapis.com/v0/b/caritas-revolution-438c3.appspot.com/o/images%2FLogo.png?alt=media&token=00a1ca94-a602-42e4-94c8-365948689545"
					height="50px"
					width="350px" />
				<Typography style={{ fontWeight: 'bold' }}>ADMIN</Typography>
				<br />
				<form className={classes.form} noValidate onSubmit={handleFormSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						error={!!emailError}
						helperText={emailError}
						onChange={handleEmailChange}
						value={user_email}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						id="password"
						type="password"
						autoComplete="current-password"
						error={!!passwordError}
						helperText={passwordError}
						onChange={handlePasswordChange}
						value={password}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						Login
					</Button>
				</form>
				<div style={{ padding: '10px' }} />
				{/* <Typography align="center">
               			<Link underline="always" to="/forgot" className={classes.link} style={{ textDecoration: "none", color: '#2057c0',fontSize: '18px'}}>
                           Forgot password?
                       </Link>
                    </Typography> */}
				<Button variant="contained" onClick={handleClickOpen}> Forgot password?</Button>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Send Password Reset?</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							A password reset will be sent to the following email:
						</DialogContentText>
						<TextField
							id="outlined-basic"
							label="Email"
							type="email"
							variant="outlined"
							style={{ width: '100%' }}
							onChange={handleOnChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSubmit} color="primary" autoFocus>
							Send Email
						</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					open={toastOpen}
					onClose={handleToastClose}
					autoHideDuration={6000}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					message={toastMessage}
				></Snackbar>
			</Box>
			<Box mt={8}>
				<Typography variant="body2" color="textSecondary" align="center">
					{"Copyright ©"}&nbsp;
					<a style={{ color: "#0133C0" }} href="https://caritasrevolution.com/"> Caritas Revolution </a>
					&nbsp;{new Date().getFullYear()}{"."}
				</Typography>
			</Box>
		</Container>
	)
}