import React, { useState, useReducer, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Input,
  TextField,
  ImageList,
  ImageListItem,
  IconButton,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleRounded from "@material-ui/icons/RemoveCircleRounded";
import { makeStyles } from "@material-ui/core/styles";
import pdf_icon from "./pdf_icon.png";
import { RiDeleteBin5Line } from "react-icons/ri";

import Table from "../Components/Table";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function reducer(state, action) {
  switch (action.type) {
    case "add_row":
      return {
        ...state,
        skipReset: true,
        data: [...state.data, {}],
      };
    case "update_cell":
      state = {
        ...state,
        skipReset: true,
        data: state.data.map((row, index) => {
          if (index === action.rowIndex) {
            return {
              ...state.data[action.rowIndex],
              [action.columnId]: action.value,
            };
          }
          return row;
        }),
      };
      return state;
    case "enable_reset":
      state = {
        ...state,
        data: action.data,
      };
      return state;
    default:
      return state;
  }
}

const ProjectDialog = ({
  causes,
  open,
  formmode,
  addProject,
  changeCauseName,
  causeDisplayName,
  close,
  changeAmountGoal,
  amount,
  changeProjectName,
  country,
  changeVideo,
  changemileStone,
  project_name,
  project_ngo_location,
  project_content,
  contact,
  changeCountry,
  project_milestones,
  changeImage,
  image,
  youtube_video_id,
  inputimageFields,
  changeMoreImageField,
  inputFields,
  addmorefield,
  project_description,
  changeProjectDescription,
  changeProjectNGO,
  changeContact,
  changeProjectContent,
  newTotalCost,
  project_detail,
  changerowsize,
  handleDeleteImage,
  changeInputField,
                         deleteVideo,
                         projectStatus
}) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, project_detail);
  const [realTotal, setRealTotal] = useState(amount);
  const [newTotalPercent, setNewTotalPercent] = useState(newTotalCost);
  const [projectName, setProjectName] = useState(false);
  const [goal, setGoal] = useState(false);
  const [projectContent, setprojectContent] = useState(false);
  const [fundingGoal, setFundingGoal] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const [location, setLocation] = useState(false);
  const [contactName, setContact] = useState(false);
  const [video, setVideo] = useState([
    {
      videoLinks: "",
    },
  ]);
  const [multipleImage, setMultipleImage] = useState([
    {
      imageData: "",
    },
  ]);


  const updateAmountGoal = (e) => {
    setRealTotal(e.target.value);
    changeAmountGoal(e);
  };

  const updateProject = () => {
    addProject(state.data, newTotalPercent);
  };

  const handleClose = () => {
    setNewTotalPercent({});
    close();
  };

  useEffect(() => {
    setRealTotal(amount);
  }, [amount]);

  useEffect(() => {
    dispatch({ type: "enable_reset", data: project_detail });
  }, [project_detail]);

  const handleAddVideo = () => {
    setVideo([
      ...video,
      {
        videoLinks: "",
      },
    ]);
  };
  const handleRemoveVideo = (index) => {
    const rows = [...video];
    rows.splice(index, 1);
    setVideo(rows);
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>{formmode ? "Add New" : "Update"} Project</DialogTitle>
      <ValidatorForm onSubmit={updateProject}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Cause Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={causeDisplayName}
                  onChange={(event) => {
                    const causeDisplayName = event.target.value;
                    const selectedCause = causes.find(
                      (c) => c.cause_display_name === causeDisplayName
                    );
                    changeCauseName(selectedCause);
                  }}
                  autoWidth
                >
                  {causes.map((cause) => (
                    <MenuItem
                      key={cause.id}
                      value={cause.cause_display_name}
                      selected={causeDisplayName === cause.cause_display_name}
                    >
                      {cause.cause_display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {formmode ? (
              <Grid item xs={6}>
                {fundingGoal && (
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Funding Goal"
                    onChange={(e) => updateAmountGoal(e)}
                    name="amount goal"
                    value={amount}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 1000000,
                        min: 100,
                      },
                    }}
                  />
                )}
                {fundingGoal ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setFundingGoal(false)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<RemoveCircleRounded/>}
                    >
                      Remove Funding Goal
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setFundingGoal(true)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<AddCircleIcon/>}
                    >
                      Add Funding Goal
                    </Button>
                   }
              </Grid>
            ) : !projectStatus? (
              <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Funding Goal"
                    onChange={(e) => updateAmountGoal(e)}
                    name="amount goal"
                    value={amount}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 1000000,
                        min: 100,
                      },
                    }}
                  />
              </Grid>
            ):''}
            <Grid item xs={6}>
              {formmode ? (
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    {projectName && (
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Project Name"
                        onChange={(event) => changeProjectName(event)}
                        name={project_name}
                        // value={project_name}
                        // validators={["required"]}
                        errormessages={["this field is required"]}
                        autoComplete="off"
                      />
                    )}
                  </Grid>
                  {projectName ?
                      <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setProjectName(false)}
                          // onClick={changerowsize}
                          className={classes.button}
                          startIcon={<RemoveCircleRounded/>}
                      >
                        Remove Project Name
                      </Button>
                      :
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setProjectName(true)}
                          // onClick={changerowsize}
                          className={classes.button}
                          startIcon={<AddCircleIcon/>}
                      >
                        Add Project Name
                      </Button>
                  }
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Project Name"
                      onChange={(event) => changeProjectName(event)}
                      name={project_name}
                      value={project_name}
                      // validators={["required"]}
                      errormessages={["this field is required"]}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            {formmode ? (
              <Grid item xs={6}>
                {showCountry && (
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Country"
                    onChange={changeCountry}
                    name="country"
                    value={country}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                  />
                )}
                {showCountry ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowCountry(false)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<RemoveCircleRounded/>}
                    >
                      Remove Country
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowCountry(true)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<AddCircleIcon/>}
                    >
                      Add Country
                    </Button>
                }
              </Grid>
            ) : (
              <Grid item xs={6}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Country"
                  onChange={changeCountry}
                  name="country"
                  value={country}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  autoComplete="off"
                />
              </Grid>
            )}
            {formmode ? (
              <Grid item xs={6}>
                {goal && (
                  <TextField
                    label="Goal"
                    multiline
                    minRows={4}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    validators={["required"]}
                    errormessages={["this field is required"]}
                    name="project_description"
                    value={project_description}
                    onChange={changeProjectDescription}
                  />
                )}
                {goal ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setGoal(false)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<RemoveCircleRounded/>}
                    >
                      Remove Goal
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setGoal(true)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<AddCircleIcon/>}
                    >
                      Add Goal
                    </Button>
                }
              </Grid>
            ) : (
              <Grid item xs={6}>
                <TextField
                  label="Goal"
                  multiline
                  minRows={4}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  validators={["required"]}
                  errormessages={["this field is required"]}
                  name="project_description"
                  value={project_description}
                  onChange={changeProjectDescription}
                />
              </Grid>
            )}
            {formmode ? (
              <Grid item xs={6}>
                {location && (
                  <TextField
                    multiline
                    minRows={4}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="NGO and Location"
                    onChange={changeProjectNGO}
                    name="project_ngo_location"
                    value={project_ngo_location}
                    validators={["required"]}
                    errormessages={["this field is required"]}
                  />
                )}
                {location ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setLocation(false)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<RemoveCircleRounded/>}
                    >
                      Remove NGO Location
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setLocation(true)}
                        // onClick={changerowsize}
                        className={classes.button}
                        startIcon={<AddCircleIcon/>}
                    >
                      Add NGO Location
                    </Button>
                }
              </Grid>
            ) : (
              <Grid item xs={6}>
                <TextField
                  multiline
                  minRows={4}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="NGO and Location"
                  onChange={changeProjectNGO}
                  name="project_ngo_location"
                  value={project_ngo_location}
                  validators={["required"]}
                  errormessages={["this field is required"]}
                />
              </Grid>
            )}
            {formmode ? (
                <Grid item xs={6}>
                  {projectContent && (
                      <TextField
                          label="Project Content"
                          multiline
                          minRows={4}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          validators={["required"]}
                          errormessages={["this field is required"]}
                          name="project_content"
                          value={project_content}
                          onChange={changeProjectContent}
                      />
                  )}
                  {projectContent ?
                      <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setprojectContent(false)}
                          // onClick={changerowsize}
                          className={classes.button}
                          startIcon={<RemoveCircleRounded/>}
                      >
                        Remove Content Details
                      </Button>
                      :
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setprojectContent(true)}
                          // onClick={changerowsize}
                          className={classes.button}
                          startIcon={<AddCircleIcon/>}
                      >
                        Add Content Details
                      </Button>
                  }
                </Grid>
            ) : (
                <Grid item xs={6}>
                  <TextField
                      label="Project Content"
                      multiline
                      minRows={4}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      validators={["required"]}
                      errormessages={["this field is required"]}
                      name="project_content"
                      value={project_content}
                      onChange={changeProjectContent}
                  />
                </Grid>
            )}
            <Grid item xs={6}>
              {formmode ? (
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      {contactName && (
                          <TextValidator
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Contact Number"
                              onChange={(event) => changeContact(event)}
                              name={contact}
                              // value={project_name}
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                              autoComplete="off"
                          />
                      )}
                    </Grid>
                    {contactName ?
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setContact(false)}
                            // onClick={changerowsize}
                            className={classes.button}
                            startIcon={<RemoveCircleRounded/>}
                        >
                          Remove Contact Details
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setContact(true)}
                            // onClick={changerowsize}
                            className={classes.button}
                            startIcon={<AddCircleIcon/>}
                        >
                          Add Contact Details
                        </Button>
                    }
                  </Grid>
              ) : (
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <TextValidator
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Contact Number"
                          onChange={(event) => changeContact(event)}
                          name={contact}
                          value={contact}
                          // validators={["required"]}
                          errormessages={["this field is required"]}
                          autoComplete="off"
                      />
                    </Grid>
                  </Grid>
              )}
            </Grid>


            {/*{formmode ? (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    /!* {inputFields.map((input, index) => {*/}
            {/*      return ( *!/*/}
            {/*    <Grid item xs={12}>*/}
            {/*      {video?.map((item, index) => {*/}
            {/*        return (*/}
            {/*          <TextValidator*/}
            {/*            variant="outlined"*/}
            {/*            margin="normal"*/}
            {/*            fullWidth*/}
            {/*            label="Video Id"*/}
            {/*            onChange={(event) => changeVideo(event)}*/}
            {/*            name="videoLinks"*/}
            {/*            // value={youtube_video_id}*/}
            {/*            // validators={["required"]}*/}
            {/*            errormessages={["this field is required"]}*/}
            {/*            autoComplete="off"*/}
            {/*          />*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </Grid>*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={handleAddVideo}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Videos Link*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={handleRemoveVideo}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Remove Videos Link*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*) : (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <TextValidator*/}
            {/*        variant="outlined"*/}
            {/*        margin="normal"*/}
            {/*        fullWidth*/}
            {/*        label="Video Id"*/}
            {/*        onChange={(event) => changeVideo(event)}*/}
            {/*        // name={index}*/}
            {/*        // value={youtube_video_id}*/}
            {/*        // validators={["required"]}*/}
            {/*        errormessages={["this field is required"]}*/}
            {/*        autoComplete="off"*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*    {Array.isArray(youtube_video_id) &&*/}
            {/*      youtube_video_id.map((input, index) => {*/}
            {/*        return (*/}
            {/*          <Grid item xs={12} key={index}>*/}
            {/*            <TextValidator*/}
            {/*              variant="outlined"*/}
            {/*              margin="normal"*/}
            {/*              fullWidth*/}
            {/*              label="Video Id"*/}
            {/*              onChange={(event) => changeVideo(index, event)}*/}
            {/*              name={index}*/}
            {/*              value={input}*/}
            {/*              // validators={["required"]}*/}
            {/*              errormessages={["this field is required"]}*/}
            {/*              autoComplete="off"*/}
            {/*            />*/}
            {/*          </Grid>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={changerowsize}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Videos Link*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*)}*/}
            {formmode ? (
                <Grid item xs={6}>
                  {inputFields.map((input, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                          <TextValidator
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Video Id"
                              onChange={(event) => changeVideo(index, event)}
                              name={index}
                              // value={youtube_video_id}
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                              autoComplete="off"
                          />
                          <IconButton
                              aria-label="Delete Video"
                              onClick={() => deleteVideo(index)}
                          >
                            <RiDeleteBin5Line />
                          </IconButton>
                        </Grid>
                    );
                  })}
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={changerowsize}
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                  >
                    Add Videos Link
                  </Button>
                </Grid>
            ) : (
                <Grid item xs={6}>
                  {inputFields.map((input, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                          <TextValidator
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Video Id"
                              onChange={(event) => changeVideo(index, event)}
                              name={index}
                              // value={youtube_video_id}
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                              autoComplete="off"
                          />
                        </Grid>
                    );
                  })}
                  {youtube_video_id?.length ?
                  youtube_video_id.map((input, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                          <TextValidator
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Video Id"
                              onChange={(event) => changeVideo(index, event)}
                              name={index}
                              value={input}
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                              autoComplete="off"
                          />
                        </Grid>
                    );
                  }):''}
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={changerowsize}
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                  >
                    Add Videos Link
                  </Button>
                </Grid>
            )}
            <Grid item xs={6}>
              <Table
                total={realTotal}
                data={state.data}
                dispatch={dispatch}
                totalCost={newTotalPercent}
                changeTotalCost={(value) => setNewTotalPercent(value)}
              />
            </Grid>
            {/*{formmode ? (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    {inputimageFields.map((input, index) => {*/}
            {/*      return (*/}
            {/*        <Grid item xs={12} lg={6} key={index}>*/}
            {/*          <Input*/}
            {/*            fullWidth*/}
            {/*            accept="image/*"*/}
            {/*            hidden*/}
            {/*            name={`${index}`}*/}
            {/*            type="file"*/}
            {/*            // validators={["required"]}*/}
            {/*            errormessages={["this field is required"]}*/}
            {/*            onChange={(event) => changeImage(index, event)}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*    <ImageList variant="masonry" cols={3} gap={8}>*/}
            {/*      {Array.isArray(image) && image.length > 0 ? (*/}
            {/*        image.map((input, index) => (*/}
            {/*          <ImageListItem key={index}>*/}
            {/*            {input*/}
            {/*              .substring(input.lastIndexOf("."))*/}
            {/*              .split("?alt")[0] === ".pdf" ? (*/}
            {/*              <img*/}
            {/*                src={pdf_icon}*/}
            {/*                style={{*/}
            {/*                  marginTop: "30px",*/}
            {/*                  width: "100px",*/}
            {/*                  height: "100px",*/}
            {/*                }}*/}
            {/*                alt="img"*/}
            {/*                color="primary"*/}
            {/*                onClick={() =>*/}
            {/*                  window.open(input, "_blank", "noreferrer")*/}
            {/*                }*/}
            {/*              />*/}
            {/*            ) : (*/}
            {/*              <img*/}
            {/*                src={input}*/}
            {/*                alt="image"*/}
            {/*                style={{ width: "100px", height: "100px" }}*/}
            {/*              />*/}
            {/*            )}*/}
            {/*            <IconButton*/}
            {/*              aria-label="Delete Image"*/}
            {/*              onClick={() => handleDeleteImage(index)}*/}
            {/*            >*/}
            {/*              <RiDeleteBin5Line />*/}
            {/*            </IconButton>*/}
            {/*          </ImageListItem>*/}
            {/*        ))*/}
            {/*      ) : (*/}
            {/*        <input type="file" name="image" />*/}
            {/*      )}*/}
            {/*    </ImageList>*/}

            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={changeMoreImageField}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Image*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*) : (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    {inputimageFields.map((input, index) => {*/}
            {/*      return (*/}
            {/*        <Grid item xs={12} lg={6} key={index}>*/}
            {/*          <Input*/}
            {/*            fullWidth*/}
            {/*            accept="image/*"*/}
            {/*            hidden*/}
            {/*            name={index}*/}
            {/*            type="file"*/}
            {/*            onChange={(event) => changeImage(index, event)}*/}
            {/*            // validators={["required"]}*/}
            {/*            errormessages={["this field is required"]}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*    <ImageList variant="masonry" cols={3} gap={8}>*/}
            {/*      {Array.isArray(image) &&*/}
            {/*        image.map((input, index) => (*/}
            {/*          <ImageListItem key={index}>*/}
            {/*            {input*/}
            {/*              .substring(input.lastIndexOf("."))*/}
            {/*              .split("?alt")[0] === ".pdf" ? (*/}
            {/*              <img*/}
            {/*                src={pdf_icon}*/}
            {/*                style={{*/}
            {/*                  marginTop: "30px",*/}
            {/*                  width: "100px",*/}
            {/*                  height: "100px",*/}
            {/*                }}*/}
            {/*                color="primary"*/}
            {/*                onClick={(url = input) =>*/}
            {/*                  window.open(input, "_blank", "noreferrer")*/}
            {/*                }*/}
            {/*              />*/}
            {/*            ) : (*/}
            {/*              <img*/}
            {/*                src={input}*/}
            {/*                alt="image"*/}
            {/*                style={{ width: "100px", height: "100px" }}*/}
            {/*              />*/}
            {/*            )}*/}
            {/*            <IconButton*/}
            {/*              aria-label="Delete Image"*/}
            {/*              onClick={() => handleDeleteImage(index)}*/}
            {/*            >*/}
            {/*              <RiDeleteBin5Line />*/}
            {/*            </IconButton>*/}
            {/*          </ImageListItem>*/}
            {/*        ))}*/}
            {/*    </ImageList>*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={changeMoreImageField}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Image*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*)}*/}


            {formmode ? (
                <Grid item xs={6}>
                  {inputimageFields.map((input, index) => {
                    return (
                        <Grid item xs={12} lg={6} key={index}>
                          <Input
                              fullWidth
                              accept="image/*"
                              // hidden
                              name={index}
                              type="file"
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                              onChange={(event) => changeImage(index, event)}
                          />
                        </Grid>
                    );
                  })}
                  <ImageList variant="masonry" cols={3} gap={8}>
                    {image?.length &&
                    image.map((input, index) => (
                        <ImageListItem key={index}>
                          {input
                              .substring(input.lastIndexOf("."))
                              .split("?alt")[0] === ".pdf" ? (
                              <img
                                  src={pdf_icon}
                                  style={{
                                    marginTop: "30px",
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  alt="img"
                                  color="primary"
                                  onClick={(url = input) =>
                                      window.open(input, "_blank", "noreferrer")
                                  }
                              />
                          ) : (
                              <img
                                  src={input}
                                  alt="image"
                                  style={{ width: "100px", height: "100px" }}
                              />
                          )}
                          <IconButton
                              aria-label="Delete Image"
                              onClick={() => handleDeleteImage(index)}
                          >
                            <RiDeleteBin5Line />
                          </IconButton>
                        </ImageListItem>
                    ))}
                  </ImageList>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={changeMoreImageField}
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                  >
                    Add Image
                  </Button>
                </Grid>
            ) : (
                <Grid item xs={6}>
                  {inputimageFields.map((input, index) => {
                    return (
                        <Grid item xs={12} lg={6} key={index}>
                          <Input
                              fullWidth
                              accept="image/*"
                              // hidden
                              name={index}
                              type="file"
                              onChange={(event) => changeImage(index, event)}
                              // validators={["required"]}
                              errormessages={["this field is required"]}
                          />
                        </Grid>
                    );
                  })}
                  <ImageList variant="masonry" cols={3} gap={8}>
                    {image?.length &&
                    image.map((input, index) => (
                        <ImageListItem key={index}>
                          {input
                              .substring(input.lastIndexOf("."))
                              .split("?alt")[0] === ".pdf" ? (
                              <img
                                  src={pdf_icon}
                                  style={{
                                    marginTop: "30px",
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  color="primary"
                                  onClick={(url = input) =>
                                      window.open(input, "_blank", "noreferrer")
                                  }
                              />
                          ) : (
                              <img
                                  src={input}
                                  alt="image"
                                  style={{ width: "100px", height: "100px" }}
                              />
                          )}
                          <IconButton
                              aria-label="Delete Image"
                              onClick={() => handleDeleteImage(index)}
                          >
                            <RiDeleteBin5Line />
                          </IconButton>
                        </ImageListItem>
                    ))}
                  </ImageList>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={changeMoreImageField}
                      className={classes.button}
                      startIcon={<AddCircleIcon />}
                  >
                    Add Image
                  </Button>
                </Grid>
            )}


            {/* new image code here*/}

            {/*{formmode ? (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    {inputimageFields.map((input, index) => {*/}
            {/*      return (*/}
            {/*        <Grid item xs={12} lg={6} key={index}>*/}
            {/*          <Input*/}
            {/*            fullWidth*/}
            {/*            accept="image/*"*/}
            {/*            hidden*/}
            {/*            name={`${index}`}*/}
            {/*            type="file"*/}
            {/*            // validators={["required"]}*/}
            {/*            errormessages={["this field is required"]}*/}
            {/*            onChange={(event) => changeImage(index, event)}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*    <ImageList variant="masonry" cols={3} gap={8}>*/}
            {/*      {Array.isArray(image) && image.length > 0 ? (*/}
            {/*        image.map((input, index) => (*/}
            {/*          <ImageListItem key={index}>*/}
            {/*            {input*/}
            {/*              .substring(input.lastIndexOf("."))*/}
            {/*              .split("?alt")[0] === ".pdf" ? (*/}
            {/*              <img*/}
            {/*                src={pdf_icon}*/}
            {/*                style={{*/}
            {/*                  marginTop: "30px",*/}
            {/*                  width: "100px",*/}
            {/*                  height: "100px",*/}
            {/*                }}*/}
            {/*                alt="img"*/}
            {/*                color="primary"*/}
            {/*                onClick={() =>*/}
            {/*                  window.open(input, "_blank", "noreferrer")*/}
            {/*                }*/}
            {/*              />*/}
            {/*            ) : (*/}
            {/*              <img*/}
            {/*                src={input}*/}
            {/*                alt="image"*/}
            {/*                style={{ width: "100px", height: "100px" }}*/}
            {/*              />*/}
            {/*            )}*/}
            {/*            <IconButton*/}
            {/*              aria-label="Delete Image"*/}
            {/*              onClick={() => handleDeleteImage(index)}*/}
            {/*            >*/}
            {/*              <RiDeleteBin5Line />*/}
            {/*            </IconButton>*/}
            {/*          </ImageListItem>*/}
            {/*        ))*/}
            {/*      ) : (*/}
            {/*        // Handle the case when image is not an array or is empty*/}
            {/*        // You can display a message or alternative content here.*/}
            {/*        <p>No images to display</p>*/}
            {/*      )}*/}
            {/*    </ImageList>*/}

            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={changeMoreImageField}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Image*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*) : (*/}
            {/*  <Grid item xs={6}>*/}
            {/*    {inputimageFields.map((input, index) => {*/}
            {/*      return (*/}
            {/*        <Grid item xs={12} lg={6} key={index}>*/}
            {/*          <Input*/}
            {/*            fullWidth*/}
            {/*            accept="image/*"*/}
            {/*            hidden*/}
            {/*            name={index}*/}
            {/*            type="file"*/}
            {/*            onChange={(event) => changeImage(index, event)}*/}
            {/*            // validators={["required"]}*/}
            {/*            errormessages={["this field is required"]}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*    <ImageList variant="masonry" cols={3} gap={8}>*/}
            {/*      {image.map((input, index) => (*/}
            {/*        <ImageListItem key={index}>*/}
            {/*          {input*/}
            {/*            .substring(input.lastIndexOf("."))*/}
            {/*            .split("?alt")[0] === ".pdf" ? (*/}
            {/*            <img*/}
            {/*              src={pdf_icon}*/}
            {/*              style={{*/}
            {/*                marginTop: "30px",*/}
            {/*                width: "100px",*/}
            {/*                height: "100px",*/}
            {/*              }}*/}
            {/*              color="primary"*/}
            {/*              onClick={(url = input) =>*/}
            {/*                window.open(input, "_blank", "noreferrer")*/}
            {/*              }*/}
            {/*            />*/}
            {/*          ) : (*/}
            {/*            <img*/}
            {/*              src={input}*/}
            {/*              alt="image"*/}
            {/*              style={{ width: "100px", height: "100px" }}*/}
            {/*            />*/}
            {/*          )}*/}
            {/*          <IconButton*/}
            {/*            aria-label="Delete Image"*/}
            {/*            onClick={() => handleDeleteImage(index)}*/}
            {/*          >*/}
            {/*            <RiDeleteBin5Line />*/}
            {/*          </IconButton>*/}
            {/*        </ImageListItem>*/}
            {/*      ))}*/}
            {/*    </ImageList>*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      onClick={changeMoreImageField}*/}
            {/*      className={classes.button}*/}
            {/*      startIcon={<AddCircleIcon />}*/}
            {/*    >*/}
            {/*      Add Image*/}
            {/*    </Button>*/}
            {/*  </Grid>*/}
            {/*)}*/}

            {/* image code above */}
            {formmode ? (
              <Grid item xs={6}>
                {addmorefield.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Project Milestone"
                        onChange={(event) => changemileStone(index, event)}
                        name="Project _milestone"
                        // value={youtube_video_id}
                        // validators={["required"]}
                        errormessages={["this field is required"]}
                        autoComplete="off"
                      />
                    </Grid>
                  );
                })}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changeInputField}
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                >
                  Add more project milestone
                </Button>
              </Grid>
            ) : (
              <Grid item xs={6}>
                {addmorefield.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Project Milestone"
                        onChange={(event) => changemileStone(index, event)}
                        name="Project _milestone"
                        // value={youtube_video_id}
                        // validators={["required"]}
                        errormessages={["this field is required"]}
                        autoComplete="off"
                      />
                    </Grid>
                  );
                })}
                {project_milestones.map((input, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Project Milestone"
                        onChange={(event) => changemileStone(index, event)}
                        name={index}
                        value={input}
                        // value={youtube_video_id}
                        // validators={["required"]}
                        errormessages={["this field is required"]}
                        autoComplete="off"
                      />
                    </Grid>
                  );
                })}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changeInputField}
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                >
                  Add more project milestone
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="secondary">
            {formmode ? "Add" : "Update"}
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default ProjectDialog;
