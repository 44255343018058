import React, {useState, useEffect, useMemo} from "react";
import {useTable, useFlexLayout, useResizeColumns, useSortBy} from "react-table";
import Cell from "./Cell";
import Header from "./Header";
import PlusIcon from "../assets/Plus";
import "./Table.css"

const columns = [
    {
        id: "cost",
        label: "Cost",
        accessor: "cost",
        minWidth: 100,
        dataType: "text",
        options: []
    },
    {
        id: "amount",
        label: "In $",
        accessor: "amount",
        minWidth: 100,
        dataType: "number",
        options: []
    },
    {
        id: "percent",
        label: "In %",
        accessor: "percent",
        minWidth: 100,
        dataType: "number",
        options: []
    }
];

const defaultColumn = {
    minWidth: 50,
    maxWidth: 200,
    Cell: Cell,
    Header: Header,
    sortType: "alphanumericFalsyLast"
};

export default function Table({
    total, data, dispatch: dataDispatch, totalCost, changeTotalCost
}) {

    const [newTotal, setNewTotal] = useState(0);
    const [newPercent, setNewPercent] = useState(0);

    const sortTypes = useMemo(
        () => ({
            alphanumericFalsyLast(rowA, rowB, columnId, desc) {
                if (!rowA.values[columnId] && !rowB.values[columnId]) {
                    return 0;
                }
                if (!rowA.values[columnId]) {
                    return desc ? -1 : 1;
                }
                if (!rowB.values[columnId]) {
                    return desc ? 1 : -1;
                }
                return isNaN(rowA.values[columnId]) ? rowA.values[columnId].localeCompare(rowB.values[columnId]) : rowA.values[columnId] - rowB.values[columnId];
            }
        }),
        []
    );

    const {getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
        {
            columns,
            data,
            defaultColumn,
            dataDispatch,
            autoResetSortBy: true,
            autoResetFilters: true,
            autoResetRowState: true,
            sortTypes
        },
        useFlexLayout,
        useResizeColumns,
        useSortBy
    );

    useEffect(() => {
        if (data !== null && data !== undefined) {
          let new_total = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].amount !== undefined && data[i].amount !== null) {
              let percent =
                Math.round(
                  (parseFloat(data[i].amount) / parseFloat(total)) *  100  * 10000
                ) / 10000;
              // console.log(percent, data[i].percent, "percent, data[i].percent ");
              if (data[i].percent !== percent) {
                dataDispatch({
                  type: "update_cell",
                  columnId: "percent",
                  rowIndex: i,
                  value: percent,
                });
              }
              new_total = parseFloat(data[i].amount) + new_total;
            }
          }
          if (new_total !== 0) {
            setNewTotal(new_total);
            let new_percent =
              Math.round(
                (parseFloat(new_total) / parseFloat(total))  * 100 * 10000
              ) / 10000;
            setNewPercent(new_percent);
            changeTotalCost({ total: new_total, percent: new_percent });
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => {
        if (totalCost.total !== null && totalCost.total !== undefined) {
            setNewTotal(totalCost.total);
        }
        if (totalCost.percent !== null && totalCost.percent !== undefined) {
            setNewPercent(totalCost.percent);
        }
    }, [totalCost])

    return (
        <>
            <div className="table">
                <div className='tr d-flex'>
                    <div className='th-content th w-69'>Total Cost of the Project:</div>
                    <div className='th-content th input-human'>{total}</div>
                </div>
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className='tr'>
                        {headerGroup.headers.map((column) => column.render("Header"))}
                    </div>
                ))}
                <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className='tr'>
                                {row.cells.map((cell) => (
                                    <div {...cell.getCellProps()} className='td'>
                                        {cell.render("Cell")}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                    <div className='tr add-row' onClick={() => dataDispatch({type: "add_row"})}>
                        <PlusIcon />New column
                    </div>
                </div>
                <div className='tr d-flex'>
                    <div className='th-content th'>Total Cost</div>
                    <div className='th-content th input-logic'>{newTotal}</div>
                    <div className='th-content th input-logic'>{newPercent}</div>
                </div>
            </div>
        </>
    );
}